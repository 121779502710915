<template>
  <div>
    <h3 ref="intro">
      Symmetry
    </h3>
    <p>
      In mathematics, a shape or an object is said to have <i>symmetry</i> if it could be folded in half such that one half is the mirror image of the other half.
      This means &mdash; if we place a mirror along the fold line, the image of the left half in the mirror will be identical to the right half.
      A shape that has symmetry is known as a <i>symmetrical shape</i>. The fold line, which is the plane of mirror, is known as line of symmetry.
    </p>
    <h3 ref="types">
      Common Examples of Symmetric Shapes
    </h3>
    <ul class="a">
      <li>
        <h5> Square </h5>
        Take a square and fold it along one of its diagonals. You will notice that the two halves are identical to each other.
      </li>
      <li>
        <h5> Equilateral Triangle </h5>
        You can fold an equilateral triangle along the line connecting any of its corner to the mid-point of the opposing side &mdash; two halves will be identical.
      </li>
      <li>
        <h5> Regular Hexagon</h5>
        A hexagon can be folded along a line connecting any two opposite corners, and two halves will be identical to each other.
      </li>
      <li>
        <h5> Circle</h5>
        A circle can be folded along any of its diameter, and the two halves will be identical.
      </li>
    </ul>
    <br>
    <h3 ref="pg">
      MagicGraph | Symmetric vs. Asymmetric Shapes
    </h3>
    <p> This MagicGraph provides a visual description of some common symmetrical and asymmetrical shapes.</p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Tools',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Symmetry ';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is Symmetry?', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Common Geometric Tools', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Symmetry',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
